export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const LOGINCODE_REQUEST = 'LOGINCODE_REQUEST';
export const LOGINCODE_SUCCESS = 'LOGINCODE_SUCCESS';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';

export const APP_ALERT = 'APP_ALERT';
export const APP_ALERT_CLEAR = 'APP_ALERT_CLEAR';

export const TOGGLE_DAY_REQUEST = 'TOGGLE_DAY_REQUEST';
export const TOGGLE_DAY_SUCCESS = 'TOGGLE_DAY_SUCCESS';

export const GET_DATES_REQUEST = 'GET_DATES_REQUEST';
export const GET_DATES_SUCCESS = 'GET_DATES_SUCCESS';
export const GET_DATES_FAILURE = 'GET_DATES_FAILURE';

export const SHORTEN_URL_REQUEST = 'SHORTEN_URL_REQUEST';
export const SHORTEN_URL_SUCCESS = 'SHORTEN_URL_SUCCESS';
export const SHORTEN_URL_FAILURE = 'SHORTEN_URL_FAILURE';

export const DECODE_URL_REQUEST = 'DECODE_URL_REQUEST';
export const DECODE_URL_SUCCESS = 'DECODE_URL_SUCCESS';
